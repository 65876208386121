.list {
  text-align: left;
  margin: auto;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.submit-form {
  max-width: 300px;
  margin: auto;
}

.edit-form {
  max-width: 300px;
  margin: auto;
}

.navbar-brand {
  padding-left: 1em;
}

.selectable-items-table tbody tr.selected,
.selectable-items-list li.selected {
  background-color: #bacbe6;
  border-color: #bacbe6;
}

.selectable-items-table tbody tr.selected:hover,
.selectable-items-table tbody tr:hover,
.selectable-items-list li.selected:hover,
.selectable-items-list li:hover {
  cursor: pointer;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.spinner-placeholder {
  min-height: 1026px;
}

.stat_005 {
  color: #000;
}
.stat_01 {
  color: #666;
}
.stat_other {
  color: #aaa;
}
th.sortableHeader.nws {
  white-space:nowrap;
}
th.sortableHeader button {
  opacity:0;
  transform:rotate(0deg);
  transition: opacity .25s ease-in-out;
  -moz-transition: opacity .25s ease-in-out;
  -webkit-transition: opacity .25s ease-in-out;
}
th.sortableHeader.sortAsc button ,
th.sortableHeader.sortDesc button ,
th.sortableHeader:hover button {
  opacity:1;
}
th.sortableHeader.sortDesc button {
  transform:rotate(180deg);
}
